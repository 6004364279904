import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

type CheckoutFormProps = {
  clientSecret: string;
};

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PK);

export const CheckoutForm = ({ clientSecret }: CheckoutFormProps) => {
  const options = {
    clientSecret,
  };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};
