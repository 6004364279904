import { useMemo } from 'react'; // Import useMemo from React
import Header from '@components/header';
import { NavbarFooter, NavbarLogo, NavbarMain } from '@components/navbar/index.ts';
import { Page } from '@components/page/page.tsx';
import { useAppShellStore } from '@stores/appshell.store.ts';
import { createFileRoute, Outlet, redirect, useRouterState } from '@tanstack/react-router';
import { AppShell, Burger, Group } from '@mantine/core';


export const Route = createFileRoute('/app')({
  beforeLoad: ({ context }) => {
    if (!context.isAuthenticated) {
      throw redirect({
        to: '/auth/login',
        search: {
          logout: true,
        },
      });
    }
  },
  component: AuthenticatedApp,
});

function AuthenticatedApp() {
  const { isNavbarCollapsed, toggleNavbarCollapsed } = useAppShellStore();

  const matches = useRouterState({ select: (s) => s.matches });
  const currentPageName = matches[matches.length - 1].context.meta?.title || 'App';

  const appShell = useMemo(
    () => (
      <>
        <AppShell.Header>
          <Group h="100%" px="md">
            <Burger
              opened={!isNavbarCollapsed}
              onClick={toggleNavbarCollapsed}
              hiddenFrom="sm"
              size="sm"
            />
            <Header />
          </Group>
        </AppShell.Header>
        <AppShell.Navbar p="md">
          <AppShell.Section>
            <Burger
              opened={!isNavbarCollapsed}
              onClick={toggleNavbarCollapsed}
              hiddenFrom="sm"
              size="sm"
            />
            <NavbarLogo />
          </AppShell.Section>
          <br />
          <AppShell.Section grow>
            <NavbarMain />
          </AppShell.Section>
          <AppShell.Section>
            <NavbarFooter />
          </AppShell.Section>
        </AppShell.Navbar>
        <AppShell.Main>
          <Outlet />
        </AppShell.Main>
      </>
    ),
    [isNavbarCollapsed, toggleNavbarCollapsed]
  );

  return <Page title={currentPageName}>{appShell}</Page>;
}
