import { GetPaymentRequest } from '@buf/khiman_class-lib.bufbuild_es/proto/payment/v1/payment_pb';
import { PaymentService } from '@buf/khiman_class-lib.connectrpc_es/proto/payment/v1/payment_connect';
import {
  createPayment,
  getPayment,
} from '@buf/khiman_class-lib.connectrpc_query-es/proto/payment/v1/payment-PaymentService_connectquery';
import { useMutation, useQuery } from '@connectrpc/connect-query';
import { useCustomTransport } from '@hooks/useCustomTransport.tsx';
import { queryClient } from '@utils/queryclient.ts';
import { notifications } from '@mantine/notifications';

type GetPaymentInput = Partial<GetPaymentRequest>;

type UsePaymentProps = {
  input: GetPaymentInput;
  requiresAuth?: boolean;
};

export const usePayment = ({ input, requiresAuth = true }: UsePaymentProps) => {
  const transport = useCustomTransport(requiresAuth);

  return useQuery(getPayment, input, {
    transport,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!input.id,
  });
};

export const useCreatePayment = () => {
  const transport = useCustomTransport(true);

  return useMutation(createPayment, {
    transport,
    retry: false,
    onError: (err) => {
      queryClient.invalidateQueries({ queryKey: [PaymentService.typeName] });
      notifications.show({
        title: 'Erreur de paiement',
        message: 'Une erreur est survenue lors du paiement: ' + err.message,
        color: 'red',
      });
    },
  });
};
