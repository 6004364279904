import React from 'react';
import { UserRole } from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { useMe } from '@hooks/services/auth.tsx';
import { useUser } from '@hooks/services/user.tsx';
import { Navigate } from '@tanstack/react-router';

export type RoleGuardProps = {
  children: React.ReactNode;
  roles: UserRole[];
};

export const RoleGuard = (props: RoleGuardProps) => {
  const { children, roles } = props;
  const userId = useMe();
  const { data, isLoading, isError } = useUser({ input: { id: userId } });
  const ownRole = data?.user?.role;

  if (isLoading) {
    return null;
  }

  if (!isLoading && (!ownRole || !roles.includes(ownRole) || isError)) {
    return <Navigate to="/auth" search={{ logout: false }} />;
  }

  return <>{children}</>;
};
