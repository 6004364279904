/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthImport } from './routes/auth'
import { Route as AppImport } from './routes/app'
import { Route as IndexImport } from './routes/index'
import { Route as AuthRegisterImport } from './routes/auth/register'
import { Route as AuthLoginImport } from './routes/auth/login'
import { Route as AppTeacherImport } from './routes/app/teacher'
import { Route as AppStudentImport } from './routes/app/student'
import { Route as AppProfileImport } from './routes/app/profile'
import { Route as AppAdminImport } from './routes/app/admin'
import { Route as AppTeacherCoursesImport } from './routes/app/teacher/courses'
import { Route as AppTeacherBillingImport } from './routes/app/teacher/billing'
import { Route as AppAdminUsersImport } from './routes/app/admin/users'
import { Route as AppAdminSubjectsImport } from './routes/app/admin/subjects'
import { Route as AppAdminSettingsImport } from './routes/app/admin/settings'
import { Route as AppAdminLevelsImport } from './routes/app/admin/levels'
import { Route as AppAdminCoursesImport } from './routes/app/admin/courses'
import { Route as AppAdminBillingImport } from './routes/app/admin/billing'
import { Route as AppStudentCoursesSearchImport } from './routes/app/student/courses/search'
import { Route as AppStudentCoursesReturnImport } from './routes/app/student/courses/return'
import { Route as AppStudentCoursesRegisteredImport } from './routes/app/student/courses/registered'

// Create/Update Routes

const AuthRoute = AuthImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any)

const AppRoute = AppImport.update({
  id: '/app',
  path: '/app',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthRegisterRoute = AuthRegisterImport.update({
  id: '/register',
  path: '/register',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const AppTeacherRoute = AppTeacherImport.update({
  id: '/teacher',
  path: '/teacher',
  getParentRoute: () => AppRoute,
} as any)

const AppStudentRoute = AppStudentImport.update({
  id: '/student',
  path: '/student',
  getParentRoute: () => AppRoute,
} as any)

const AppProfileRoute = AppProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => AppRoute,
} as any)

const AppAdminRoute = AppAdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => AppRoute,
} as any)

const AppTeacherCoursesRoute = AppTeacherCoursesImport.update({
  id: '/courses',
  path: '/courses',
  getParentRoute: () => AppTeacherRoute,
} as any)

const AppTeacherBillingRoute = AppTeacherBillingImport.update({
  id: '/billing',
  path: '/billing',
  getParentRoute: () => AppTeacherRoute,
} as any)

const AppAdminUsersRoute = AppAdminUsersImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => AppAdminRoute,
} as any)

const AppAdminSubjectsRoute = AppAdminSubjectsImport.update({
  id: '/subjects',
  path: '/subjects',
  getParentRoute: () => AppAdminRoute,
} as any)

const AppAdminSettingsRoute = AppAdminSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => AppAdminRoute,
} as any)

const AppAdminLevelsRoute = AppAdminLevelsImport.update({
  id: '/levels',
  path: '/levels',
  getParentRoute: () => AppAdminRoute,
} as any)

const AppAdminCoursesRoute = AppAdminCoursesImport.update({
  id: '/courses',
  path: '/courses',
  getParentRoute: () => AppAdminRoute,
} as any)

const AppAdminBillingRoute = AppAdminBillingImport.update({
  id: '/billing',
  path: '/billing',
  getParentRoute: () => AppAdminRoute,
} as any)

const AppStudentCoursesSearchRoute = AppStudentCoursesSearchImport.update({
  id: '/courses/search',
  path: '/courses/search',
  getParentRoute: () => AppStudentRoute,
} as any)

const AppStudentCoursesReturnRoute = AppStudentCoursesReturnImport.update({
  id: '/courses/return',
  path: '/courses/return',
  getParentRoute: () => AppStudentRoute,
} as any)

const AppStudentCoursesRegisteredRoute =
  AppStudentCoursesRegisteredImport.update({
    id: '/courses/registered',
    path: '/courses/registered',
    getParentRoute: () => AppStudentRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/app': {
      id: '/app'
      path: '/app'
      fullPath: '/app'
      preLoaderRoute: typeof AppImport
      parentRoute: typeof rootRoute
    }
    '/auth': {
      id: '/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/app/admin': {
      id: '/app/admin'
      path: '/admin'
      fullPath: '/app/admin'
      preLoaderRoute: typeof AppAdminImport
      parentRoute: typeof AppImport
    }
    '/app/profile': {
      id: '/app/profile'
      path: '/profile'
      fullPath: '/app/profile'
      preLoaderRoute: typeof AppProfileImport
      parentRoute: typeof AppImport
    }
    '/app/student': {
      id: '/app/student'
      path: '/student'
      fullPath: '/app/student'
      preLoaderRoute: typeof AppStudentImport
      parentRoute: typeof AppImport
    }
    '/app/teacher': {
      id: '/app/teacher'
      path: '/teacher'
      fullPath: '/app/teacher'
      preLoaderRoute: typeof AppTeacherImport
      parentRoute: typeof AppImport
    }
    '/auth/login': {
      id: '/auth/login'
      path: '/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/auth/register': {
      id: '/auth/register'
      path: '/register'
      fullPath: '/auth/register'
      preLoaderRoute: typeof AuthRegisterImport
      parentRoute: typeof AuthImport
    }
    '/app/admin/billing': {
      id: '/app/admin/billing'
      path: '/billing'
      fullPath: '/app/admin/billing'
      preLoaderRoute: typeof AppAdminBillingImport
      parentRoute: typeof AppAdminImport
    }
    '/app/admin/courses': {
      id: '/app/admin/courses'
      path: '/courses'
      fullPath: '/app/admin/courses'
      preLoaderRoute: typeof AppAdminCoursesImport
      parentRoute: typeof AppAdminImport
    }
    '/app/admin/levels': {
      id: '/app/admin/levels'
      path: '/levels'
      fullPath: '/app/admin/levels'
      preLoaderRoute: typeof AppAdminLevelsImport
      parentRoute: typeof AppAdminImport
    }
    '/app/admin/settings': {
      id: '/app/admin/settings'
      path: '/settings'
      fullPath: '/app/admin/settings'
      preLoaderRoute: typeof AppAdminSettingsImport
      parentRoute: typeof AppAdminImport
    }
    '/app/admin/subjects': {
      id: '/app/admin/subjects'
      path: '/subjects'
      fullPath: '/app/admin/subjects'
      preLoaderRoute: typeof AppAdminSubjectsImport
      parentRoute: typeof AppAdminImport
    }
    '/app/admin/users': {
      id: '/app/admin/users'
      path: '/users'
      fullPath: '/app/admin/users'
      preLoaderRoute: typeof AppAdminUsersImport
      parentRoute: typeof AppAdminImport
    }
    '/app/teacher/billing': {
      id: '/app/teacher/billing'
      path: '/billing'
      fullPath: '/app/teacher/billing'
      preLoaderRoute: typeof AppTeacherBillingImport
      parentRoute: typeof AppTeacherImport
    }
    '/app/teacher/courses': {
      id: '/app/teacher/courses'
      path: '/courses'
      fullPath: '/app/teacher/courses'
      preLoaderRoute: typeof AppTeacherCoursesImport
      parentRoute: typeof AppTeacherImport
    }
    '/app/student/courses/registered': {
      id: '/app/student/courses/registered'
      path: '/courses/registered'
      fullPath: '/app/student/courses/registered'
      preLoaderRoute: typeof AppStudentCoursesRegisteredImport
      parentRoute: typeof AppStudentImport
    }
    '/app/student/courses/return': {
      id: '/app/student/courses/return'
      path: '/courses/return'
      fullPath: '/app/student/courses/return'
      preLoaderRoute: typeof AppStudentCoursesReturnImport
      parentRoute: typeof AppStudentImport
    }
    '/app/student/courses/search': {
      id: '/app/student/courses/search'
      path: '/courses/search'
      fullPath: '/app/student/courses/search'
      preLoaderRoute: typeof AppStudentCoursesSearchImport
      parentRoute: typeof AppStudentImport
    }
  }
}

// Create and export the route tree

interface AppAdminRouteChildren {
  AppAdminBillingRoute: typeof AppAdminBillingRoute
  AppAdminCoursesRoute: typeof AppAdminCoursesRoute
  AppAdminLevelsRoute: typeof AppAdminLevelsRoute
  AppAdminSettingsRoute: typeof AppAdminSettingsRoute
  AppAdminSubjectsRoute: typeof AppAdminSubjectsRoute
  AppAdminUsersRoute: typeof AppAdminUsersRoute
}

const AppAdminRouteChildren: AppAdminRouteChildren = {
  AppAdminBillingRoute: AppAdminBillingRoute,
  AppAdminCoursesRoute: AppAdminCoursesRoute,
  AppAdminLevelsRoute: AppAdminLevelsRoute,
  AppAdminSettingsRoute: AppAdminSettingsRoute,
  AppAdminSubjectsRoute: AppAdminSubjectsRoute,
  AppAdminUsersRoute: AppAdminUsersRoute,
}

const AppAdminRouteWithChildren = AppAdminRoute._addFileChildren(
  AppAdminRouteChildren,
)

interface AppStudentRouteChildren {
  AppStudentCoursesRegisteredRoute: typeof AppStudentCoursesRegisteredRoute
  AppStudentCoursesReturnRoute: typeof AppStudentCoursesReturnRoute
  AppStudentCoursesSearchRoute: typeof AppStudentCoursesSearchRoute
}

const AppStudentRouteChildren: AppStudentRouteChildren = {
  AppStudentCoursesRegisteredRoute: AppStudentCoursesRegisteredRoute,
  AppStudentCoursesReturnRoute: AppStudentCoursesReturnRoute,
  AppStudentCoursesSearchRoute: AppStudentCoursesSearchRoute,
}

const AppStudentRouteWithChildren = AppStudentRoute._addFileChildren(
  AppStudentRouteChildren,
)

interface AppTeacherRouteChildren {
  AppTeacherBillingRoute: typeof AppTeacherBillingRoute
  AppTeacherCoursesRoute: typeof AppTeacherCoursesRoute
}

const AppTeacherRouteChildren: AppTeacherRouteChildren = {
  AppTeacherBillingRoute: AppTeacherBillingRoute,
  AppTeacherCoursesRoute: AppTeacherCoursesRoute,
}

const AppTeacherRouteWithChildren = AppTeacherRoute._addFileChildren(
  AppTeacherRouteChildren,
)

interface AppRouteChildren {
  AppAdminRoute: typeof AppAdminRouteWithChildren
  AppProfileRoute: typeof AppProfileRoute
  AppStudentRoute: typeof AppStudentRouteWithChildren
  AppTeacherRoute: typeof AppTeacherRouteWithChildren
}

const AppRouteChildren: AppRouteChildren = {
  AppAdminRoute: AppAdminRouteWithChildren,
  AppProfileRoute: AppProfileRoute,
  AppStudentRoute: AppStudentRouteWithChildren,
  AppTeacherRoute: AppTeacherRouteWithChildren,
}

const AppRouteWithChildren = AppRoute._addFileChildren(AppRouteChildren)

interface AuthRouteChildren {
  AuthLoginRoute: typeof AuthLoginRoute
  AuthRegisterRoute: typeof AuthRegisterRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthLoginRoute: AuthLoginRoute,
  AuthRegisterRoute: AuthRegisterRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/app': typeof AppRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/app/admin': typeof AppAdminRouteWithChildren
  '/app/profile': typeof AppProfileRoute
  '/app/student': typeof AppStudentRouteWithChildren
  '/app/teacher': typeof AppTeacherRouteWithChildren
  '/auth/login': typeof AuthLoginRoute
  '/auth/register': typeof AuthRegisterRoute
  '/app/admin/billing': typeof AppAdminBillingRoute
  '/app/admin/courses': typeof AppAdminCoursesRoute
  '/app/admin/levels': typeof AppAdminLevelsRoute
  '/app/admin/settings': typeof AppAdminSettingsRoute
  '/app/admin/subjects': typeof AppAdminSubjectsRoute
  '/app/admin/users': typeof AppAdminUsersRoute
  '/app/teacher/billing': typeof AppTeacherBillingRoute
  '/app/teacher/courses': typeof AppTeacherCoursesRoute
  '/app/student/courses/registered': typeof AppStudentCoursesRegisteredRoute
  '/app/student/courses/return': typeof AppStudentCoursesReturnRoute
  '/app/student/courses/search': typeof AppStudentCoursesSearchRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/app': typeof AppRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/app/admin': typeof AppAdminRouteWithChildren
  '/app/profile': typeof AppProfileRoute
  '/app/student': typeof AppStudentRouteWithChildren
  '/app/teacher': typeof AppTeacherRouteWithChildren
  '/auth/login': typeof AuthLoginRoute
  '/auth/register': typeof AuthRegisterRoute
  '/app/admin/billing': typeof AppAdminBillingRoute
  '/app/admin/courses': typeof AppAdminCoursesRoute
  '/app/admin/levels': typeof AppAdminLevelsRoute
  '/app/admin/settings': typeof AppAdminSettingsRoute
  '/app/admin/subjects': typeof AppAdminSubjectsRoute
  '/app/admin/users': typeof AppAdminUsersRoute
  '/app/teacher/billing': typeof AppTeacherBillingRoute
  '/app/teacher/courses': typeof AppTeacherCoursesRoute
  '/app/student/courses/registered': typeof AppStudentCoursesRegisteredRoute
  '/app/student/courses/return': typeof AppStudentCoursesReturnRoute
  '/app/student/courses/search': typeof AppStudentCoursesSearchRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/app': typeof AppRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/app/admin': typeof AppAdminRouteWithChildren
  '/app/profile': typeof AppProfileRoute
  '/app/student': typeof AppStudentRouteWithChildren
  '/app/teacher': typeof AppTeacherRouteWithChildren
  '/auth/login': typeof AuthLoginRoute
  '/auth/register': typeof AuthRegisterRoute
  '/app/admin/billing': typeof AppAdminBillingRoute
  '/app/admin/courses': typeof AppAdminCoursesRoute
  '/app/admin/levels': typeof AppAdminLevelsRoute
  '/app/admin/settings': typeof AppAdminSettingsRoute
  '/app/admin/subjects': typeof AppAdminSubjectsRoute
  '/app/admin/users': typeof AppAdminUsersRoute
  '/app/teacher/billing': typeof AppTeacherBillingRoute
  '/app/teacher/courses': typeof AppTeacherCoursesRoute
  '/app/student/courses/registered': typeof AppStudentCoursesRegisteredRoute
  '/app/student/courses/return': typeof AppStudentCoursesReturnRoute
  '/app/student/courses/search': typeof AppStudentCoursesSearchRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/app'
    | '/auth'
    | '/app/admin'
    | '/app/profile'
    | '/app/student'
    | '/app/teacher'
    | '/auth/login'
    | '/auth/register'
    | '/app/admin/billing'
    | '/app/admin/courses'
    | '/app/admin/levels'
    | '/app/admin/settings'
    | '/app/admin/subjects'
    | '/app/admin/users'
    | '/app/teacher/billing'
    | '/app/teacher/courses'
    | '/app/student/courses/registered'
    | '/app/student/courses/return'
    | '/app/student/courses/search'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/app'
    | '/auth'
    | '/app/admin'
    | '/app/profile'
    | '/app/student'
    | '/app/teacher'
    | '/auth/login'
    | '/auth/register'
    | '/app/admin/billing'
    | '/app/admin/courses'
    | '/app/admin/levels'
    | '/app/admin/settings'
    | '/app/admin/subjects'
    | '/app/admin/users'
    | '/app/teacher/billing'
    | '/app/teacher/courses'
    | '/app/student/courses/registered'
    | '/app/student/courses/return'
    | '/app/student/courses/search'
  id:
    | '__root__'
    | '/'
    | '/app'
    | '/auth'
    | '/app/admin'
    | '/app/profile'
    | '/app/student'
    | '/app/teacher'
    | '/auth/login'
    | '/auth/register'
    | '/app/admin/billing'
    | '/app/admin/courses'
    | '/app/admin/levels'
    | '/app/admin/settings'
    | '/app/admin/subjects'
    | '/app/admin/users'
    | '/app/teacher/billing'
    | '/app/teacher/courses'
    | '/app/student/courses/registered'
    | '/app/student/courses/return'
    | '/app/student/courses/search'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AppRoute: typeof AppRouteWithChildren
  AuthRoute: typeof AuthRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AppRoute: AppRouteWithChildren,
  AuthRoute: AuthRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/app",
        "/auth"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/app": {
      "filePath": "app.tsx",
      "children": [
        "/app/admin",
        "/app/profile",
        "/app/student",
        "/app/teacher"
      ]
    },
    "/auth": {
      "filePath": "auth.tsx",
      "children": [
        "/auth/login",
        "/auth/register"
      ]
    },
    "/app/admin": {
      "filePath": "app/admin.tsx",
      "parent": "/app",
      "children": [
        "/app/admin/billing",
        "/app/admin/courses",
        "/app/admin/levels",
        "/app/admin/settings",
        "/app/admin/subjects",
        "/app/admin/users"
      ]
    },
    "/app/profile": {
      "filePath": "app/profile.tsx",
      "parent": "/app"
    },
    "/app/student": {
      "filePath": "app/student.tsx",
      "parent": "/app",
      "children": [
        "/app/student/courses/registered",
        "/app/student/courses/return",
        "/app/student/courses/search"
      ]
    },
    "/app/teacher": {
      "filePath": "app/teacher.tsx",
      "parent": "/app",
      "children": [
        "/app/teacher/billing",
        "/app/teacher/courses"
      ]
    },
    "/auth/login": {
      "filePath": "auth/login.tsx",
      "parent": "/auth"
    },
    "/auth/register": {
      "filePath": "auth/register.tsx",
      "parent": "/auth"
    },
    "/app/admin/billing": {
      "filePath": "app/admin/billing.tsx",
      "parent": "/app/admin"
    },
    "/app/admin/courses": {
      "filePath": "app/admin/courses.tsx",
      "parent": "/app/admin"
    },
    "/app/admin/levels": {
      "filePath": "app/admin/levels.tsx",
      "parent": "/app/admin"
    },
    "/app/admin/settings": {
      "filePath": "app/admin/settings.tsx",
      "parent": "/app/admin"
    },
    "/app/admin/subjects": {
      "filePath": "app/admin/subjects.tsx",
      "parent": "/app/admin"
    },
    "/app/admin/users": {
      "filePath": "app/admin/users.tsx",
      "parent": "/app/admin"
    },
    "/app/teacher/billing": {
      "filePath": "app/teacher/billing.tsx",
      "parent": "/app/teacher"
    },
    "/app/teacher/courses": {
      "filePath": "app/teacher/courses.tsx",
      "parent": "/app/teacher"
    },
    "/app/student/courses/registered": {
      "filePath": "app/student/courses/registered.tsx",
      "parent": "/app/student"
    },
    "/app/student/courses/return": {
      "filePath": "app/student/courses/return.tsx",
      "parent": "/app/student"
    },
    "/app/student/courses/search": {
      "filePath": "app/student/courses/search.tsx",
      "parent": "/app/student"
    }
  }
}
ROUTE_MANIFEST_END */
