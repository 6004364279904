import { usePayment } from '@hooks/services/payment.tsx';
import { Route } from '@routes/app/student/courses/return';
import { IconCalendar, IconCheck, IconCoinEuro, IconCreditCard } from '@tabler/icons-react';
import { Container, Group, Paper, Stack, Text, ThemeIcon, Title } from '@mantine/core';

export const Return = () => {
  const { payment_id } = Route.useSearch();
  const { data } = usePayment({ input: { id: payment_id } });
  const payment = data?.payment;

  const formatCurrency = (amount?: number, currency?: string) => {
    if (!amount || !currency) return '';

    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: currency,
    }).format(amount / 100);
  };

  return (
    <Container size="sm" className="mt-8">
      <Paper p="xl" radius="md" withBorder className="space-y-6">
        {/* Success Header */}
        <Group align="center" className="space-y-2">
          <ThemeIcon size={64} radius="xl" color="teal">
            <IconCheck size={32} />
          </ThemeIcon>
          <Title order={1} className="text-teal-600">
            Paiement reçu avec succès!
          </Title>
          <Text color="dimmed" size="lg">
            Merci pour votre achat
          </Text>
        </Group>

        {/* Payment Details */}
        <Stack className="mt-6">
          <Group className="border-b pb-4">
            <Group>
              <ThemeIcon size="lg" color="blue" variant="light">
                <IconCoinEuro size={20} />
              </ThemeIcon>
              <div>
                <Text size="sm" color="dimmed">
                  Montant payé
                </Text>
                <Text fw={600}>{formatCurrency(payment?.amount, payment?.amountCurrency)}</Text>
              </div>
            </Group>
          </Group>

          <Group className="border-b pb-4">
            <Group>
              <ThemeIcon size="lg" color="violet" variant="light">
                <IconCreditCard size={20} />
              </ThemeIcon>
              <div>
                <Text size="sm" color="dimmed">
                  ID Transaction
                </Text>
                <Text size="sm" className="font-mono">
                  {payment?.id}
                </Text>
              </div>
            </Group>
          </Group>

          <Group>
            <Group>
              <ThemeIcon size="lg" color="orange" variant="light">
                <IconCalendar size={20} />
              </ThemeIcon>
              <div>
                <Text size="sm" color="dimmed">
                  Date de la transaction
                </Text>
                <Text>{payment?.updatedAt?.toDate()?.toLocaleString() || ''}</Text>
              </div>
            </Group>
          </Group>
        </Stack>

        {/* Course Information */}
        <Paper withBorder p="md" radius="md" className="bg-gray-50 mt-6" mt="1rem">
          <Group>
            <div>
              <Text size="sm" color="dimmed">
                Course ID
              </Text>
              <Text className="font-mono">{payment?.courseId}</Text>
            </div>
            <div>
              <Text size="sm" color="dimmed">
                Student ID
              </Text>
              <Text className="font-mono">{payment?.studentId}</Text>
            </div>
          </Group>
        </Paper>
      </Paper>
    </Container>
  );
};

export default Return;
