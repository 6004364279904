import { useMemo } from 'react';
import { UpdateOwnUserPasswordRequestSchema, UpdateOwnUserRequestSchema } from '@api/dtos/user.ts';
import { UpdateUserOperation } from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { useMe } from '@hooks/services/auth.tsx';
import { useUpdateUser, useUser } from '@hooks/services/user.tsx';
import { useForm } from '@tanstack/react-form';
import { useStore } from '@tanstack/react-store';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { generateDirtyFieldMask } from '@utils/field-mask.ts';
import { Box, Button, Fieldset, Flex, LoadingOverlay, TextInput } from '@mantine/core';

export const ProfilePage = () => {
  const userId = useMe();
  const { data, isLoading, isFetching } = useUser({ input: { id: userId } });
  const mutateUser = useUpdateUser();

  const defaultValues = useMemo(
    () => ({
      firstName: data?.user?.firstName ?? '',
      lastName: data?.user?.lastName ?? '',
      email: data?.user?.email ?? '',
      id: data?.user?.id ?? '',
    }),
    [data?.user]
  );

  const defaultPasswordValues = useMemo(
    () => ({
      id: data?.user?.id ?? '',
      password: '',
      confirmPassword: '',
    }),
    [data?.user]
  );

  const isLoaderVisible = isLoading || isFetching;

  const { Field, handleSubmit, Subscribe, store } = useForm({
    validatorAdapter: zodValidator(),
    defaultValues,
    validators: {
      onSubmit: UpdateOwnUserRequestSchema,
    },
    onSubmit: async ({ value }) => {
      console.log('Submit:', value);
      const touchedFields = generateDirtyFieldMask(fields);
      console.log('Dirty fields:', touchedFields);
      const data = new UpdateUserOperation(value);

      mutateUser.mutate(
        {
          user: data,
          updateMask: touchedFields,
        },
        {
          onSuccess: () => {
            // TODO
          },
        }
      );
    },
  });

  const fields = useStore(store, (state) => state.fieldMeta);

  const {
    Field: PasswordField,
    handleSubmit: handleSubmitPassword,
    Subscribe: PasswordSubscribe,
    store: passwordStore,
  } = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: defaultPasswordValues,
    validators: {
      onSubmit: UpdateOwnUserPasswordRequestSchema,
    },
    onSubmit: async ({ value }) => {
      console.log('Submit:', value);
      const touchedFields = generateDirtyFieldMask(passwordFields);
      console.log('Dirty fields:', touchedFields);
      const data = new UpdateUserOperation(value);
      console.log('Data:', data);

      // mutateUser.mutate(
      //   {
      //     user: data,
      //     updateMask: touchedFields,
      //   },
      //   {
      //     onSuccess: () => {
      //       // TODO
      //     },
      //   }
      // );
    },
  });

  const passwordFields = useStore(passwordStore, (state) => state.fieldMeta);

  return (
    <Box pos="relative">
      <LoadingOverlay
        visible={isLoaderVisible}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Flex justify="center" direction="column" gap="md">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Flex justify="left" direction="column" gap="md">
            <Fieldset legend="Informations personnelles" variant="filled" w="100%">
              <Field
                name="firstName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextInput
                    value={state.value ?? ''}
                    onChange={(e) => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    label="Prénom"
                    placeholder="Prénom"
                    autoComplete="name"
                    error={state.meta.errors ? state.meta.errors.join(',') : null}
                  />
                )}
              />
              <Field
                name="lastName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextInput
                    value={state.value ?? ''}
                    onChange={(e) => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    label="Nom"
                    autoComplete="family-name"
                    placeholder="Nom"
                    error={state.meta.errors ? state.meta.errors.join(',') : null}
                  />
                )}
              />
              <Field
                name="email"
                children={({ state, handleChange, handleBlur }) => (
                  <TextInput
                    value={state.value ?? ''}
                    onChange={(e) => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    label="Email"
                    autoComplete="email"
                    placeholder="Email"
                    error={state.meta.errors ? state.meta.errors.join(',') : null}
                  />
                )}
              />
            </Fieldset>
            <Subscribe
              selector={(state) => [state.isTouched]}
              children={([isTouched]) => (
                <Button
                  disabled={!isTouched || mutateUser.isPending}
                  loading={mutateUser.isPending}
                  type="submit"
                >
                  Mettre à jour mes infos personnelles
                </Button>
              )}
            />
          </Flex>
        </form>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitPassword();
          }}
        >
          <Flex justify="left" direction="column" gap="md" pos="relative">
            <Fieldset legend="Mot de passe" variant="filled" w="100%">
              <PasswordField
                name="password"
                children={({ state, handleChange, handleBlur }) => (
                  <TextInput
                    defaultValue={state.value}
                    onChange={(e) => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    label="Mot de passe"
                    type="password"
                    autoComplete="current-password"
                    placeholder="Mot de passe"
                    error={state.meta.errors ? state.meta.errors.join(',') : null}
                  />
                )}
              />
              <PasswordField
                name="confirmPassword"
                children={({ state, handleChange, handleBlur }) => (
                  <TextInput
                    defaultValue={state.value}
                    onChange={(e) => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    label="Confirmer le mot de passe"
                    type="password"
                    autoComplete="current-password"
                    placeholder="Confirmer le mot de passe"
                    error={state.meta.errors ? state.meta.errors.join(',') : null}
                  />
                )}
              />
            </Fieldset>
            <PasswordSubscribe
              selector={(state) => [state.isTouched]}
              children={([isTouched]) => (
                <Button
                  disabled={!isTouched || mutateUser.isPending}
                  loading={mutateUser.isPending}
                  type="submit"
                >
                  Mettre à jour mon mot de passe
                </Button>
              )}
            />
          </Flex>
        </form>
      </Flex>
    </Box>
  );
};
