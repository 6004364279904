import { useCallback, useEffect, useMemo } from 'react';
import { UpdateSubjectRequestSchema } from '@api/dtos/subject';
import { UpdateSubjectOperation } from '@buf/khiman_class-lib.bufbuild_es/proto/subject/v1/subject_pb';
import { useListLevels } from '@hooks/services/level.tsx';
import { useSubject, useUpdateSubject } from '@hooks/services/subject';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { generateDirtyFieldMask } from '@utils/field-mask';
import { useStore } from '@tanstack/react-store';
import {
  Button,
  Divider,
  Drawer,
  Fieldset,
  Flex,
  LoadingOverlay,
  Select,
  Text,
  TextInput,
} from '@mantine/core';

interface SubjectDrawerProps {
  opened: boolean;
  onClose: () => void;
  subjectId?: string;
}

export const SubjectEditDrawer = (props: SubjectDrawerProps) => {
  const { opened, onClose, subjectId } = props;
  const { data, isLoading, isFetching } = useSubject({ input: { id: subjectId } });
  const mutateSubject = useUpdateSubject();

  const { data: levelsData } = useListLevels({
    input: {},
  });

  // Here we memoize the levels options to avoid re-rendering the component since this is unlikely to change often
  const levelOptions = useMemo(
    () =>
      levelsData?.levels?.map((level) => ({
        value: level.id,
        label: level.name,
      })) ?? [],
    [levelsData?.levels]
  );

  const defaultValues = useMemo(
    () => ({
      name: data?.subject?.name ?? '',
      description: data?.subject?.description ?? '',
      id: data?.subject?.id ?? '',
      levelId: data?.subject?.levelId ?? '',
    }),
    [data?.subject]
  );

  const { Field, handleSubmit, reset, Subscribe, store } = useForm({
    validatorAdapter: zodValidator(),
    defaultValues,
    validators: {
      onSubmit: UpdateSubjectRequestSchema,
    },
    onSubmit: async ({ value }) => {
      console.log('Submit:', value);
      const touchedFields = generateDirtyFieldMask(fields);
      console.log('Dirty fields:', touchedFields);
      const data = new UpdateSubjectOperation(value);
      console.log('Data:', data);

      mutateSubject.mutate(
        {
          subject: data,
          updateMask: touchedFields,
        },
        {
          onSuccess: () => {
            reset();
            onClose();
          },
        }
      );
    },
  });

  const fields = useStore(store, (state) => state.fieldMeta);

  useEffect(() => {
    if (opened && data?.subject) {
      reset();
    }
  }, [opened, data?.subject, defaultValues, reset]);

  const isLoaderVisible = isLoading || isFetching;

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  return (
    <Drawer offset={8} radius="md" opened={opened} onClose={handleClose} position="right">
      <LoadingOverlay
        visible={isLoaderVisible}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Flex align="center" w="100%" justify="center">
        {data?.subject?.name}
      </Flex>
      <Flex mt="md" direction="row" align="center" gap="md" justify="center">
        <Flex direction="column" gap="1" align="center">
          <Text>{data?.subject?.description}</Text>
          <Text c="dimmed">{data?.subject?.description}</Text>
        </Flex>
      </Flex>
      <Divider mt="md" mb="md" />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Flex justify="left" direction="column" gap="md">
          <Fieldset legend="Niveau" variant="filled" w="100%">
            <Field
              name="name"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value as string ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Nom"
                  placeholder="Nom"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="description"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value as string ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Description"
                  placeholder="Description"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="levelId"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  value={state.value?.toString() as string ?? ''}
                  onChange={(value) => handleChange(value as string)}
                  onBlur={handleBlur}
                  label="Niveau"
                  placeholder="Sélectionner un niveau"
                  data={levelOptions}
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                  searchable
                />
              )}
            />
          </Fieldset>
          <Subscribe
            selector={(state) => [state.isDirty]}
            children={([isDirty]) => (
              <Button
                disabled={!isDirty || mutateSubject.isPending}
                loading={mutateSubject.isPending}
                type="submit"
              >
                Enregistrer
              </Button>
            )}
          />
        </Flex>
      </form>
    </Drawer>
  );
};
