import dayjs from 'dayjs';
import {
  Course,
  EnrollmentStatus,
} from '@buf/khiman_class-lib.bufbuild_es/proto/course/v1/course_pb';
import { CheckoutForm } from '@components/checkout-form/CheckoutForm.tsx';
import CourseEditModal from '@components/course-edit-modal/course-edit-modal.tsx';
import { useMe } from '@hooks/services/auth.tsx';
import { useCourse, useEnrollStudent, useUnenrollStudent } from '@hooks/services/course.tsx';
import { useLevel } from '@hooks/services/level.tsx';
import { useCreatePayment } from '@hooks/services/payment.tsx';
import { useSubject } from '@hooks/services/subject.tsx';
import {
  IconChevronDown,
  IconChevronRight,
  IconChevronUp,
  IconDotsVertical,
  IconExternalLink,
  IconPencil,
  IconShoppingCart,
  IconTrash,
} from '@tabler/icons-react';
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  LoadingOverlay,
  Menu,
  rem,
  Skeleton,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

export type CourseCardProps = {
  course: Course;
  asTeacher?: boolean;
};

export const CourseCard = ({ course, asTeacher = false }: CourseCardProps) => {
  const [opened, { toggle }] = useDisclosure(false);
  const [isProcessing, { open: startProcessing, close: stopProcessing }] = useDisclosure(false);

  const { data, isLoading } = useCourse({
    input: {
      id: course.id,
    },
  });

  const userId = useMe();

  const { data: courseLevel, isLoading: isCourseLevelLoading } = useLevel({
    input: {
      id: course.levelId,
    },
  });

  const { data: courseSubject, isLoading: isCourseSubjectLoading } = useSubject({
    input: {
      id: course.subjectId,
    },
  });

  const enrollStudent = useEnrollStudent();
  const unenrollStudent = useUnenrollStudent();
  const createPayment = useCreatePayment();

  const openCheckoutModal = (clientSecret: string) => {
    modals.open({
      title: 'Paiement',
      size: 'xl',
      children: <CheckoutForm clientSecret={clientSecret} />,
      closeOnClickOutside: false,
      closeOnEscape: false,
    });
  };

  if (isLoading || isCourseLevelLoading || isCourseSubjectLoading) {
    return <Skeleton />;
  }

  const startTime = dayjs(data?.course?.startTime?.toDate());
  const endTime = startTime.add(Number(data?.course?.duration?.seconds), 'second');
  const coursePrice = (data?.course?.price || 0) / 100;

  const openEditModal = (course: Course | undefined) => () => {
    return modals.open({
      title: 'Modifier le cours',
      children: (
        <CourseEditModal opened={true} onClose={() => modals.closeAll()} courseId={course?.id} />
      ),
      size: 'lg',
    });
  };

  const userIsAlreadyEnrolled =
    data?.course?.enrollments?.find(
      (enrollment) =>
        enrollment.studentId === userId && enrollment.status === EnrollmentStatus.ACTIVE
    ) !== undefined;

  const handleUnenrollment = async () => {
    try {
      console.log('Unenrolling student...');

      await unenrollStudent.mutateAsync({
        courseId: course.id,
        studentId: userId,
      });
    } catch (error) {
      console.error(error);
      notifications.show({
        title: 'Erreur',
        message: 'Une erreur est survenue lors de la désinscription. Veuillez réessayer.',
        color: 'red',
      });
    }
  };

  const handleEnrollment = async () => {
    try {
      console.log('Creating enrollment and payment...');
      startProcessing();

      // First create the enrollment
      await enrollStudent.mutateAsync({
        courseId: course.id,
        studentId: userId,
      });

      // Then create the payment and get the client secret
      const paymentResponse = await createPayment.mutateAsync({
        courseId: course.id,
        studentId: userId,
      });

      if (paymentResponse?.payment?.checkoutSessionClientSecret) {
        stopProcessing();
        openCheckoutModal(paymentResponse.payment.checkoutSessionClientSecret);
      } else {
        throw new Error('No client secret received');
      }
    } catch (error) {
      console.error(error);
      notifications.show({
        title: 'Erreur',
        message: "Une erreur est survenue lors de l'inscription. Veuillez réessayer.",
        color: 'red',
      });

      // Rollback enrollment if payment failed
      unenrollStudent.mutate({
        courseId: course.id,
        studentId: userId,
      });
    } finally {
      stopProcessing();
    }
  };

  const maxStudents = data?.course?.maxStudents || 0;
  const enrolledStudents =
    data?.course?.enrollments?.filter((enrollment) => {
      return enrollment.status === EnrollmentStatus.ACTIVE;
    }).length || 0;

  return (
    <Box
      w="100%"
      p="xs"
      mih="120px"
      style={{
        border: '1px solid #000',
        borderColor: 'var(--mantine-color-default-border)',
        borderRadius: 4,
      }}
    >
      <LoadingOverlay
        visible={isProcessing || enrollStudent.isPending || unenrollStudent.isPending}
        overlayProps={{ blur: 2 }}
      />
      <Flex direction="column" h="100%">
        <Flex direction="row" h="120px" gap="md">
          <Flex h="100%" justify="center" direction="column">
            <ActionIcon onClick={toggle}>
              {opened ? <IconChevronUp /> : <IconChevronDown />}
            </ActionIcon>
          </Flex>
          <Flex direction="column" h="100%" justify="center" miw="140px">
            <Text size="md" fw={600}>
              {startTime.format('DD/MM/YYYY HH:mm')}
            </Text>
            <Text c="dimmed" size="md">
              {endTime.format('DD/MM/YYYY HH:mm')}
            </Text>
          </Flex>
          <Divider orientation="vertical" size="sm" />
          <Flex direction="column" justify="center" gap="md" miw="200px">
            <Text size="md" fw="600">
              {data?.course?.title}
            </Text>
            <Box>
              <Badge color="red">{courseLevel?.level?.name}</Badge>{' '}
              <Badge color="grape">{courseSubject?.subject?.name}</Badge>
            </Box>
          </Flex>
          <Flex direction="row-reverse" justify="center" w="100%">
            <Flex direction="column" justify="center">
              <Box>
                <Badge color="gray">
                  {enrolledStudents}/{maxStudents} inscrits
                </Badge>
              </Box>
            </Flex>
          </Flex>
          {asTeacher && (
            <>
              <Flex direction="column" justify="center">
                <Menu shadow="md">
                  <Menu.Target>
                    <ActionIcon>
                      <IconDotsVertical />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      component="a"
                      target="_blank"
                      href={data?.course?.videoLink}
                      leftSection={<IconExternalLink style={{ width: rem(14), height: rem(14) }} />}
                    >
                      Accès Visio
                    </Menu.Item>
                    {asTeacher && (
                      <Menu.Item
                        disabled={startTime.isBefore(dayjs())}
                        onClick={openEditModal(data?.course)}
                        leftSection={<IconPencil style={{ width: rem(14), height: rem(14) }} />}
                      >
                        Modifier
                      </Menu.Item>
                    )}
                    {asTeacher && (
                      <Menu.Item
                        color="red"
                        disabled={true}
                        leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
                      >
                        Annuler
                      </Menu.Item>
                    )}
                  </Menu.Dropdown>
                </Menu>
              </Flex>
              <Flex direction="column" justify="center">
                <ActionIcon>
                  <IconChevronRight />
                </ActionIcon>
              </Flex>
            </>
          )}
          {!asTeacher && (
            <Flex direction="column" justify="center">
              <Button
                leftSection={<IconShoppingCart />}
                variant="subtle"
                loading={enrollStudent.isPending || unenrollStudent.isPending}
                onClick={userIsAlreadyEnrolled ? handleUnenrollment : handleEnrollment}
              >
                {userIsAlreadyEnrolled ? 'Se désinscrire' : "S'inscrire"}
              </Button>
            </Flex>
          )}
        </Flex>
        <Collapse in={opened}>
          <Box
            p="md"
            style={{
              border: '1px solid #000',
              borderColor: 'var(--mantine-color-default-border)',
              borderRadius: 4,
            }}
          >
            <Text size="md" fw="500">
              Description:
            </Text>
            <Text size="sm">{data?.course?.description}</Text>
            <Text size="md" fw="500">
              Liste des inscrits:
            </Text>
            <Text size="sm">Aucun inscrit</Text>
            <Text size="md" fw="500">
              Durée:
            </Text>
            <Text size="sm">
              {dayjs
                .duration(Number(data?.course?.duration?.seconds), 'seconds')
                ?.format('H[h] m[m] s[s]')}
            </Text>
            <Text size="md" fw="500">
              Prix par élève:
            </Text>
            <Text size="sm">
              {coursePrice} {data?.course?.currency}
            </Text>
            <Text size="md" fw="500">
              Prix total:
            </Text>
            <Text size="sm">
              {coursePrice * 0} {data?.course?.currency}
            </Text>
          </Box>
        </Collapse>
      </Flex>
    </Box>
  );
};
