import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CourseUpdateRequestSchema } from '@api/dtos/course';
import { UserRole } from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { Duration, Timestamp } from '@bufbuild/protobuf';
import { useCourse, useUpdateCourse } from '@hooks/services/course';
import { useListLevels } from '@hooks/services/level';
import { useListSubjects } from '@hooks/services/subject';
import { useListUsers } from '@hooks/services/user';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { generateDirtyFieldMask } from '@utils/field-mask';
import { useStore } from '@tanstack/react-store';
import {
  Button,
  Fieldset,
  Flex,
  LoadingOverlay,
  NumberInput,
  Select,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';

interface CourseEditModalProps {
  opened: boolean;
  onClose: () => void;
  courseId?: string;
}

export const CourseEditModal = ({ opened, onClose, courseId }: CourseEditModalProps) => {
  const [selectedLevelId, setSelectedLevel] = useState<string | undefined>();
  const { data, isLoading, isFetching } = useCourse({
    input: {
      id: courseId,
    },
  });

  const mutateCourse = useUpdateCourse();

  const { data: levelsData } = useListLevels({
    input: {
      pageSize: 1000,
      page: 1,
    },
  });

  const { data: subjectsData } = useListSubjects({
    input: {
      pageSize: 1000,
      page: 1,
      levelId: selectedLevelId ?? undefined,
    },
  });

  const { data: teachersData } = useListUsers({
    input: {
      pageSize: 1000,
      page: 1,
      role: UserRole.TEACHER,
    },
  });

  const defaultValues = useMemo(
    () => ({
      title: data?.course?.title ?? '',
      description: data?.course?.description ?? '',
      maxStudents: data?.course?.maxStudents ?? 10,
      levelId: data?.course?.levelId ?? '',
      subjectId: data?.course?.subjectId ?? '',
      videoLink: data?.course?.videoLink ?? '',
      startTime: data?.course?.startTime
        ? new Date(Number(data.course.startTime.seconds) * 1000)
        : new Date(),
      duration: data?.course?.duration ? Number(data.course.duration.seconds) / (60 * 60) : 1,
      teacherId: data?.course?.teacherId ?? '',
      id: data?.course?.id ?? '',
    }),
    [data?.course]
  );

  const teacherOptions = useMemo(
    () =>
      teachersData?.users?.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })) ?? [],
    [teachersData?.users]
  );

  const levelOptions = useMemo(
    () =>
      levelsData?.levels?.map((level) => ({
        value: level.id,
        label: level.name,
      })) ?? [],
    [levelsData?.levels]
  );

  const subjectOptions = useMemo(
    () =>
      subjectsData?.subjects?.map((subject) => ({
        value: subject.id,
        label: subject.name,
      })) ?? [],
    [subjectsData?.subjects]
  );

  const { Field, handleSubmit, reset, Subscribe, store } = useForm({
    validatorAdapter: zodValidator(),
    defaultValues,
    validators: {
      onSubmit: CourseUpdateRequestSchema,
    },
    onSubmit: async ({ value }) => {
      const touchedFields = generateDirtyFieldMask(fields);

      const duration = new Duration({
        seconds: BigInt(value.duration * 60 * 60),
      });
      const startTime = new Timestamp({
        seconds: BigInt(dayjs(value.startTime).unix()),
      });

      mutateCourse.mutate(
        {
          course: {
            ...value,
            duration,
            startTime,
          },
          updateMask: touchedFields,
        },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    },
  });

  const fields = useStore(store, (state) => state.fieldMeta);

  useEffect(() => {
    if (opened && data?.course) {
      reset();
      setSelectedLevel(data.course.levelId);
    }
  }, [opened, data?.course, reset]);

  const isLoaderVisible = isLoading || isFetching;

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  return (
    <div>
      <LoadingOverlay
        visible={isLoaderVisible}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void handleSubmit();
        }}
      >
        <Flex direction="column" gap="md">
          <Fieldset legend="Informations du cours" variant="filled">
            <Field
              name="title"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Nom"
                  placeholder="Nom du cours"
                  withAsterisk
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="description"
              children={({ state, handleChange, handleBlur }) => (
                <Textarea
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Description"
                  placeholder="Description du cours"
                  withAsterisk
                  autosize
                  minRows={3}
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="levelId"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  value={state.value?.toString() ?? ''}
                  onChange={(value) => {
                    handleChange(value as string);
                    setSelectedLevel(value as string);
                  }}
                  onBlur={handleBlur}
                  label="Niveau"
                  placeholder="Sélectionner un niveau"
                  data={levelOptions}
                  withAsterisk
                  searchable
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="subjectId"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  value={state.value?.toString() ?? ''}
                  onChange={(value) => handleChange(value as string)}
                  onBlur={handleBlur}
                  label="Matière"
                  placeholder="Sélectionner une matière"
                  data={subjectOptions}
                  withAsterisk
                  searchable
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="teacherId"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  value={state.value?.toString() ?? ''}
                  readOnly
                  disabled
                  onChange={(value) => handleChange(value as string)}
                  onBlur={handleBlur}
                  label="Professeur"
                  placeholder="Sélectionner un professeur"
                  data={teacherOptions}
                  withAsterisk
                  searchable
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="startTime"
              children={({ state, handleChange, handleBlur }) => (
                <DateTimePicker
                  value={state.value}
                  onChange={(value) => handleChange(value ?? new Date())}
                  onBlur={handleBlur}
                  label="Date de début"
                  placeholder="Sélectionner la date"
                  withAsterisk
                  valueFormat="DD/MM/YYYY HH:mm"
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="duration"
              children={({ state, handleChange, handleBlur }) => (
                <NumberInput
                  value={state.value}
                  onChange={(value) => handleChange(value as number)}
                  onBlur={handleBlur}
                  label="Durée (heures)"
                  placeholder="Durée du cours"
                  min={1}
                  max={10}
                  withAsterisk
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="maxStudents"
              children={({ state, handleChange, handleBlur }) => (
                <NumberInput
                  value={state.value}
                  onChange={(value) => handleChange(value as number)}
                  onBlur={handleBlur}
                  label="Nombre d'élèves max"
                  placeholder="Nombre maximum d'élèves"
                  min={1}
                  max={10}
                  withAsterisk
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="videoLink"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Lien vidéo"
                  placeholder="Lien de la visioconférence"
                  withAsterisk
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
          </Fieldset>
          <Flex justify="flex-end" mt="md">
            <Subscribe
              selector={(state) => [state.isDirty]}
              children={([isDirty]) => (
                <Button
                  type="submit"
                  disabled={!isDirty || mutateCourse.isPending}
                  loading={mutateCourse.isPending}
                >
                  Enregistrer
                </Button>
              )}
            />
          </Flex>
        </Flex>
      </form>
    </div>
  );
};

export default CourseEditModal;
