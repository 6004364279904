import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CourseUpdateRequestSchema } from '@api/dtos/course.ts';
import { UserRole } from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { Duration, Timestamp } from '@bufbuild/protobuf';
import { useCourse, useUpdateCourse } from '@hooks/services/course.tsx';
import { useListLevels } from '@hooks/services/level.tsx';
import { useListSubjects } from '@hooks/services/subject';
import { useListUsers } from '@hooks/services/user.tsx';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { generateDirtyFieldMask } from '@utils/field-mask';
import {
  Button,
  Divider,
  Drawer,
  Fieldset,
  Flex,
  LoadingOverlay,
  NumberInput,
  Select,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DateTimePicker, DateValue } from '@mantine/dates';
import { useStore } from '@tanstack/react-store';

interface CourseDrawerProps {
  opened: boolean;
  onClose: () => void;
  courseId?: string;
}

export const CourseEditDrawer = (props: CourseDrawerProps) => {
  const { opened, onClose, courseId } = props;
  const [selectedLevelId, setSelectedLevel] = useState<string | undefined>();
  const { data, isLoading, isFetching } = useCourse({ input: { id: courseId } });
  const mutateCourse = useUpdateCourse();

  const { data: levelsData } = useListLevels({
    input: {
      pageSize: 1000,
      page: 1,
    },
  });

  const { data: subjectsData } = useListSubjects({
    input: {
      pageSize: 1000,
      page: 1,
      levelId: selectedLevelId ?? undefined,
    },
  });

  const { data: teachersData } = useListUsers({
    input: {
      pageSize: 1000,
      page: 1,
      role: UserRole.TEACHER,
    },
  });

  const defaultValues = useMemo(
    () => ({
      title: data?.course?.title ?? '',
      description: data?.course?.description ?? '',
      maxStudents: data?.course?.maxStudents ?? 10,
      levelId: data?.course?.levelId ?? '',
      subjectId: data?.course?.subjectId ?? '',
      videoLink: data?.course?.videoLink ?? '',
      startTime: data?.course?.startTime
        ? new Date(Number(data.course.startTime.seconds) * 1000)
        : new Date(),
      duration: data?.course?.duration ? Number(data.course.duration.seconds) / (60 * 60) : 1,
      teacherId: data?.course?.teacherId ?? '',
      id: data?.course?.id ?? '',
    }),
    [data?.course]
  );

  const teacherOptions = useMemo(
    () =>
      teachersData?.users?.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })) ?? [],
    [teachersData?.users]
  );

  const levelOptions = useMemo(
    () =>
      levelsData?.levels?.map((level) => ({
        value: level.id,
        label: level.name,
      })) ?? [],
    [levelsData?.levels]
  );

  const subjectOptions = useMemo(
    () =>
      subjectsData?.subjects?.map((subject) => ({
        value: subject.id,
        label: subject.name,
      })) ?? [],
    [subjectsData?.subjects]
  );

  const { Field, handleSubmit, reset, Subscribe, store } = useForm({
    validatorAdapter: zodValidator(),
    defaultValues,
    validators: {
      onSubmit: CourseUpdateRequestSchema,
    },
    onSubmit: async ({ value }) => {
      console.log('Submit:', value);
      const touchedFields = generateDirtyFieldMask(fields);
      console.log('Dirty fields:', touchedFields);

      const duration = new Duration({
        seconds: BigInt(value.duration * 60 * 60),
      });
      const startTime = new Timestamp({
        seconds: BigInt(dayjs(value.startTime).unix()),
      });

      mutateCourse.mutate(
        {
          course: {
            ...value,
            duration,
            startTime,
          },
          updateMask: touchedFields,
        },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    },
    onSubmitInvalid: (errors) => {
      console.log('Errors:', errors);
    },
  });

  const fields = useStore(store, (state) => state.fieldMeta);

  useEffect(() => {
    if (opened && data?.course) {
      reset();
      setSelectedLevel(data.course.levelId);
    }
  }, [opened, data?.course, defaultValues, reset]);

  const isLoaderVisible = isLoading || isFetching;

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  return (
    <Drawer offset={8} radius="md" opened={opened} onClose={handleClose} position="right">
      <LoadingOverlay
        visible={isLoaderVisible}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Flex align="center" w="100%" justify="center">
        {data?.course?.title}
      </Flex>
      <Flex mt="md" direction="row" align="center" gap="md" justify="center">
        <Flex direction="column" gap="1" align="center">
          <Text>{data?.course?.description}</Text>
          <Text c="dimmed">
            Début:{' '}
            {data?.course?.startTime
              ? dayjs(Number(data.course.startTime.seconds) * 1000).format('DD/MM/YYYY HH:mm')
              : ''}
          </Text>
        </Flex>
      </Flex>
      <Divider mt="md" mb="md" />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void handleSubmit();
        }}
      >
        <Flex justify="left" direction="column" gap="md">
          <Fieldset legend="Niveau" variant="filled" w="100%">
            <Field
              name="title"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Nom"
                  placeholder="Nom"
                  autoComplete="off"
                  withAsterisk
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="description"
              children={({ state, handleChange, handleBlur }) => (
                <Textarea
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Description"
                  withAsterisk
                  autosize
                  placeholder="Description"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="levelId"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  value={state.value.toString() ?? ''}
                  onChange={(value) => {
                    handleChange(value as string);
                    setSelectedLevel(value as string);
                  }}
                  onBlur={handleBlur}
                  label="Niveau"
                  withAsterisk
                  placeholder="Sélectionner un niveau"
                  data={levelOptions}
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                  searchable
                />
              )}
            />
            <Field
              name="subjectId"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  value={state.value.toString() ?? ''}
                  onChange={(value) => handleChange(value as string)}
                  onBlur={handleBlur}
                  label="Matière"
                  withAsterisk
                  placeholder="Sélectionner une matière"
                  data={subjectOptions}
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                  searchable
                />
              )}
            />
            <Field
              name="teacherId"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  value={state.value.toString() ?? ''}
                  onChange={(value) => handleChange(value as string)}
                  onBlur={handleBlur}
                  label="Professeur"
                  withAsterisk
                  placeholder="Sélectionner un professeur"
                  data={teacherOptions}
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                  searchable
                />
              )}
            />
            <Field
              name="maxStudents"
              children={({ state, handleChange, handleBlur }) => (
                <NumberInput
                  value={state.value}
                  onChange={(e) => handleChange(e as number)}
                  onBlur={handleBlur}
                  min={1}
                  max={10}
                  withAsterisk
                  label="Nombre d'élèves"
                  placeholder="Nombre d'élèves"
                  autoComplete="off"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="videoLink"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Lien vidéo"
                  withAsterisk
                  placeholder="Lien vidéo"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="startTime"
              children={({ state, handleChange, handleBlur }) => (
                <DateTimePicker
                  value={state.value}
                  onChange={(value: DateValue) => {
                    handleChange(value ?? new Date());
                  }}
                  onBlur={handleBlur}
                  label="Date de début"
                  withAsterisk
                  placeholder="Date de début"
                  valueFormat="DD/MM/YYYY HH:mm"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="duration"
              children={({ state, handleChange, handleBlur }) => (
                <NumberInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e as number)}
                  onBlur={handleBlur}
                  min={1}
                  max={10}
                  withAsterisk
                  label="Durée (en heures)"
                  placeholder="Durée (en heures)"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
          </Fieldset>
          <Subscribe
            selector={(state) => [state.isDirty]}
            children={([isDirty]) => (
              <Button
                disabled={!isDirty || mutateCourse.isPending}
                loading={mutateCourse.isPending}
                type="submit"
              >
                Enregistrer
              </Button>
            )}
          />
        </Flex>
      </form>
    </Drawer>
  );
};
