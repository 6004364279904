import {
  GetCourseRequest,
  GetCourseResponse,
  ListCoursesRequest,
  ListCoursesResponse,
} from '@buf/khiman_class-lib.bufbuild_es/proto/course/v1/course_pb';
import { CourseService } from '@buf/khiman_class-lib.connectrpc_es/proto/course/v1/course_connect';
import {
  createCourse,
  enrollStudent,
  getCourse,
  listCourses,
  unenrollStudent,
  updateCourse,
} from '@buf/khiman_class-lib.connectrpc_query-es/proto/course/v1/course-CourseService_connectquery';
import { ConnectError, Transport } from '@connectrpc/connect';
import {
  ConnectQueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@connectrpc/connect-query';
import type {
  UseQueryOptions as TanStackUseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { queryClient } from '@utils/queryclient.ts';
import { notifications } from '@mantine/notifications';
import { useCustomTransport } from '../useCustomTransport';

type ListCoursesInput = Partial<ListCoursesRequest>;

type ListCoursesQueryOptions = Omit<
  TanStackUseQueryOptions<
    ListCoursesResponse,
    ConnectError,
    ListCoursesResponse,
    ConnectQueryKey<ListCoursesRequest>
  >,
  'queryKey' | 'queryFn'
>;

type UselistCoursesProps = {
  input?: ListCoursesInput;
  options?: ListCoursesQueryOptions;
  requiresAuth?: boolean;
};

export const useListCourses = ({
  input = {},
  options = {},
  requiresAuth = true,
}: UselistCoursesProps = {}): UseQueryResult<ListCoursesResponse, ConnectError> => {
  const transport = useCustomTransport(requiresAuth);

  return useQuery(listCourses, input, {
    transport,
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useInfiniteListCourses = (
  input: Partial<ListCoursesRequest>,
  options: {
    transport?: Transport;
    requiresAuth?: boolean;
  } = {}
) => {
  const { requiresAuth = true, ...queryOptions } = options;
  const transport = useCustomTransport(requiresAuth);

  return useInfiniteQuery(
    listCourses,
    {
      ...input,
      page: 1,
    },
    {
      transport,
      pageParamKey: 'page',
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.courses.length > 0 ? nextPage : undefined;
      },
      ...queryOptions,
    }
  );
};

type GetCourseInput = Partial<GetCourseRequest>;

type UseCourseProps = {
  input: GetCourseInput;
  requiresAuth?: boolean;
};

export const useCourse = ({
  input,
  requiresAuth = true,
}: UseCourseProps): UseQueryResult<GetCourseResponse, ConnectError> => {
  const transport = useCustomTransport(requiresAuth);

  return useQuery(getCourse, input, {
    transport,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!input.id,
  });
};

export const useUpdateCourse = () => {
  const transport = useCustomTransport(true);

  return useMutation(updateCourse, {
    transport,
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CourseService.typeName] });
      notifications.show({
        title: 'Cours mis à jour',
        message: 'Le cours a été mis à jour avec succès',
        color: 'teal',
      });
    },
    onError: (error) => {
      notifications.show({
        title: 'Erreur lors de la mise à jour du cours',
        message: error.message,
        color: 'red',
      });
    },
  });
};

export const useCreateCourse = () => {
  const transport = useCustomTransport(true);

  return useMutation(createCourse, {
    transport,
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CourseService.typeName] });
      notifications.show({
        title: 'Cours créé',
        message: 'Le cours a été créé avec succès',
        color: 'teal',
      });
    },
    onError: (error) => {
      notifications.show({
        title: 'Erreur lors de la création du cours',
        message: error.message,
        color: 'red',
      });
    },
  });
};

export const useEnrollStudent = () => {
  const transport = useCustomTransport(true);

  return useMutation(enrollStudent, {
    transport,
    retry: false,
    onError: (error) => {
      notifications.show({
        title: 'Erreur lors de l\'inscription de l\'étudiant',
        message: error.message,
        color: 'red',
      });
    },
  });
};

export const useUnenrollStudent = () => {
  const transport = useCustomTransport(true);

  return useMutation(unenrollStudent, {
    transport,
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CourseService.typeName] });
      notifications.show({
        title: 'Étudiant désinscrit',
        message: 'L\'étudiant a été désinscrit avec succès',
        color: 'teal',
      });
    },
    onError: (error) => {
      notifications.show({
        title: 'Erreur lors de la désinscription de l\'étudiant',
        message: error.message,
        color: 'red',
      });
    },
  });
};
