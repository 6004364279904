import { Return } from '@pages/student/return';
import { createFileRoute } from '@tanstack/react-router';

type ReturnParams = {
  payment_id: string;
};

export const Route = createFileRoute('/app/student/courses/return')({
  validateSearch: (search: Record<string, unknown>): ReturnParams => {
    if (!search.payment_id) {
      throw new Error('Missing payment_id');
    }
    return {
      payment_id: search.payment_id as string,
    };
  },
  component: RouteComponent,
});

function RouteComponent() {
  return <Return />;
}
