import { useEffect } from 'react';
import { SignupRequestSchema } from '@api/dtos/auth.ts';
import { UserRole } from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { useSignup } from '@hooks/services/auth.tsx';
import { useAuthStore } from '@stores/auth.store.ts';
import { useForm } from '@tanstack/react-form';
import { useNavigate, useRouter } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { Button, SegmentedControl, Stack, TextInput } from '@mantine/core';

export const RegisterForm = () => {
  const { isAuthenticated } = useAuthStore();
  const router = useRouter();
  const signup = useSignup(router);
  const navigate = useNavigate({ from: '/auth/register' });

  const { Field, handleSubmit } = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      role: UserRole.STUDENT,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      // @ts-expect-error
      confirmPassword: '',
    },
    validators: {
      onSubmit: SignupRequestSchema,
    },
    onSubmit: async ({ value }) => {
      console.log('Submit:', value);
      signup.mutate(value);
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate({ to: '/app' });
    }
  }, [navigate, isAuthenticated]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Stack>
        <Field
          name="role"
          children={({ state, handleChange, handleBlur }) => (
            <SegmentedControl
              data={[
                {
                  value: UserRole.STUDENT.toString(),
                  label: 'Étudiant',
                },
                {
                  value: UserRole.TEACHER.toString(),
                  label: 'Enseignant',
                },
              ]}
              value={state.value.toString()}
              onChange={(value) => handleChange(Number(value) as UserRole)}
              onBlur={handleBlur}
            />
          )}
        />
        <Field
          name="firstName"
          children={({ state, handleChange, handleBlur }) => (
            <TextInput
              defaultValue={state.value}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={handleBlur}
              label="Prénom"
              placeholder="Prénom"
              error={state.meta.errors ? state.meta.errors.join(',') : null}
            />
          )}
        />
        <Field
          name="lastName"
          children={({ state, handleChange, handleBlur }) => (
            <TextInput
              defaultValue={state.value}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={handleBlur}
              label="Nom"
              placeholder="Nom"
              error={state.meta.errors ? state.meta.errors.join(',') : null}
            />
          )}
        />
        <Field
          name="email"
          children={({ state, handleChange, handleBlur }) => (
            <TextInput
              defaultValue={state.value}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={handleBlur}
              label="Email"
              placeholder="Email"
              autoComplete="email"
              error={state.meta.errors ? state.meta.errors.join(',') : null}
            />
          )}
        />
        <Field
          name="password"
          children={({ state, handleChange, handleBlur }) => (
            <TextInput
              defaultValue={state.value}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={handleBlur}
              label="Mot de passe"
              type="password"
              autoComplete="current-password"
              placeholder="Mot de passe"
              error={state.meta.errors ? state.meta.errors.join(',') : null}
            />
          )}
        />
        <Field
          // @ts-expect-error
          name="confirmPassword"
          children={({ state, handleChange, handleBlur }) => (
            <TextInput
              defaultValue={state.value}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={handleBlur}
              label="Confirmer le mot de passe"
              type="password"
              autoComplete="current-password"
              placeholder="Confirmer le mot de passe"
              error={state.meta.errors ? state.meta.errors.join(',') : null}
            />
          )}
        />
        <Button type="submit" loading={signup.isPending}>
          Inscription
        </Button>
      </Stack>
    </form>
  );
};
